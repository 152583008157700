import * as React from "react";
import { graphql, Link } from "gatsby";

import Layout from "../components/layout";
import Img from "gatsby-image";
import { PageHeaderWithBreadcrumbs } from "../components/pageHeaderWithBreadcrumbs";

const BlogIndex = ({ data, location }) => {
  const posts = data.allMarkdownRemark.nodes;

  return (
    <Layout
      location={location}
      seoTitle="Blog"
      seoDescription="Przeczytaj najnowsze artykuły"
    >
      <main className="page-blog">
        <div className="container">
          <PageHeaderWithBreadcrumbs
            currentBreadcrumbText="Blog"
            title="Przeczytaj najnowsze artykuły"
          />

          <section className="ins-page-section pt-0">
            <div className="row">
              {posts.map((post) => {
                const title = post.frontmatter.title || post.fields.slug;

                return (
                  <div className="col-md-6" key={post.fields.slug}>
                    <article className="blog-post media">
                      <div className="blog-post-thumbnail-wrapper">
                        <Img
                          fluid={
                            post.frontmatter.featuredImage.childImageSharp.fluid
                          }
                          className="blog-post-thumbnail mr-md-3"
                          alt={post.frontmatter.title}
                          style={{
                            width: 155,
                          }}
                        />
                        <span className="blog-post-badge">Artykuł</span>
                      </div>
                      <div className="media-body">
                        <p className="blog-post-date">
                          {post.frontmatter.date}
                        </p>
                        <h5 className="blog-post-title">
                          <Link
                            to={`/blog${post.fields.slug}`}
                            rel="prev"
                            className="text-decoration-none"
                          >
                            {title}
                          </Link>
                        </h5>
                        <p
                          className="blog-post-excerpt"
                          dangerouslySetInnerHTML={{
                            __html: post.frontmatter.description,
                          }}
                          itemProp="description"
                        />
                      </div>
                    </article>
                  </div>
                );
              })}
            </div>
          </section>
        </div>
      </main>
    </Layout>
  );
};

export default BlogIndex;

export const pageQuery = graphql`
  query ($language: String!) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        fields {
          slug
        }
        frontmatter {
          date(formatString: "DD MMM YYYY", locale: $language)
          title
          description
          featuredImage {
            childImageSharp {
              fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    locales: allLocale(
      filter: { ns: { in: ["index"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
